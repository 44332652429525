<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">{{ $t('farm_config.allocation_list') }} {{ id ? $t('globalTrans.modify') : $t('globalTrans.add') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                      <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                          <b-row>
                                            <!-- fiscal year id -->
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="fiscal_year_id"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('globalTrans.fiscal_year')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="formData.fiscal_year_id"
                                                            id="fiscal_year_id"
                                                            :options="fiscalYearList"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- season -->
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Season" vid="season_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="season_id"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('farm_config.season')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            v-model="formData.season_id"
                                                            id="season_id"
                                                            :options="seasonList"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- circular -->
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Circular" vid="circular_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="circular_id"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('farm_config.circular')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                            plain
                                                            :disabled="circularLoading ? true : false"
                                                            v-model="formData.circular_id"
                                                            id="circular_id"
                                                            :options="circularList"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                            <template v-slot:first>
                                                                <b-form-select-option :value="0">{{circularLoading ? $t('globalTrans.loading') + '...' : $t('globalTrans.select')}}</b-form-select-option>
                                                            </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <!-- district -->
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="district_id"
                                                        slot-scope="{ errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('globalTrans.district')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <v-select name="district_id"
                                                          v-model="formData.district_id"
                                                          label="text"
                                                          :reduce="item => item.value"
                                                          :options= districtList
                                                          :placeholder="$t('globalTrans.select')"
                                                          :filter-by="myFilter"
                                                          />
                                                        <div class="invalid-feedback d-block">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                          </b-row>
                                            <div v-if="existAllocationDistrict">
                                                <b-alert variant="danger" show dismissible>
                                                    <b>{{$t('farm_config.allocation_exist')}}</b>
                                                </b-alert>
                                            </div>
                                             <div class="d-flex justify-content-end">
                                                <router-link v-if="$route.query.from" class="btn btn-sm btn-primary my-2" :to="{name: $route.query.from }" variant="primary btn_add_new">
                                                    <i class="fa fa-arrow-left"></i>{{ $t('farm_config.allocation') }} {{ $t('globalTrans.list') }}
                                                </router-link>
                                                <router-link v-else class="btn btn-sm btn-primary my-2" :to="{name: 'incentive_grant_service.farm_machinery.allocation.allocation_list'}" variant="primary btn_add_new">
                                                    <i class="fa fa-arrow-left"></i> {{ $t('farm_config.allocation') }} {{ $t('globalTrans.list') }}
                                                </router-link>
                                           </div>
                                           <b-overlay :show="loadMechineType">
                                           <template>
                                            <div style="font-size:14px; background-color: #66CC99; padding:6px">
                                                <h5 class="text-white text-center"> {{ $t('farm_config.allocation') }}</h5>
                                            </div>
                                            </template>
                                            <b-row>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <b-table-simple striped bordered small class="mt-2">
                                                        <b-thead>
                                                            <tr>
                                                                <b-th rowspan="2" class="text-center">{{ $t('globalTrans.sl_no') }}</b-th>
                                                                <b-th rowspan="2"  class="text-center">{{ $t('globalTrans.upazila') }} </b-th>
                                                                <template v-if="Object.keys(machineTypeList).length > 0">
                                                                    <b-th :colspan="machineType.instruments.length" class="text-center" v-for="(machineType, machineTypeIndex) in machineTypeList" :key="'mechine_type-' + machineTypeIndex">
                                                                        {{ currentLocale === 'en' ? machineType.machine_type : machineType.machine_type_bn }}
                                                                    </b-th>
                                                                </template>
                                                            </tr>
                                                            <tr class="text-center" v-if="Object.keys(machineTypeList).length > 0">
                                                                <template v-for="(machineType, machineTypeIndex) in machineTypeList">
                                                                    <b-th v-for="(machine, machineIndex) in machineType.instruments" :key="'mechine-name-' + machineTypeIndex + machineIndex" class="text-center">
                                                                        {{ currentLocale === 'en' ? machine.name : machine.name_bn }}
                                                                    </b-th>
                                                                </template>
                                                            </tr>
                                                        </b-thead>
                                                        <b-tbody>
                                                            <template v-if="Object.keys(formData.details).length > 0">
                                                                <b-tr v-for="(item, index) in formData.details" :key="index">
                                                                    <b-td  class="text-center">{{ $n(index + 1) }}</b-td>
                                                                    <b-td class="text-center">{{ getUpazilaName(item.upazilla_id) }}
                                                                        <button type="button" class="btn btn-sm btn-success" style="float:right" title="Farmer List" v-b-modal.modal-5 @click="details(item)">
                                                                            <!-- {{ $t('dae_subsidy.farmer_list') }} -->
                                                                            <i class="fa fa-users"></i>
                                                                        </button>
                                                                    </b-td>
                                                                    <b-td class="text-center" v-for="(machineType, machineTypeIndex) in item.machine_type_info" :key="'mechine_type-' + machineTypeIndex">
                                                                     <ValidationProvider name="Quantity" :vid="'machine_type_info-' + machineTypeIndex + 'instruments'">
                                                                        <b-form-group
                                                                            label-for="quantity">
                                                                            <b-form-input
                                                                            oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                                                                            id="quantity"
                                                                            v-model="machineType.quantity"
                                                                            ></b-form-input>
                                                                        </b-form-group>
                                                                    </ValidationProvider>
                                                                    </b-td>
                                                                </b-tr>
                                                            </template>
                                                            <template v-else>
                                                                <b-tr>
                                                                    <b-td colspan="4" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                                                </b-tr>
                                                            </template>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>
                                            </b-row>
                                          </b-overlay>
                                          <div class="row mt-2">
                                              <div class="col-sm-3"></div>
                                              <div class="col text-right">
                                                <b-button :disabled="existAllocationDistrict" @click="saveData(1)" variant="warning" class="mr-2">{{ $t('globalTrans.draft') }}</b-button>
                                                <b-button :disabled="existAllocationDistrict" @click="saveData(2)" variant="primary" class="mr-2">{{ $t('globalTrans.finalSave') }}</b-button>
                                                <b-button variant="danger" @click="back()">{{ $t('globalTrans.cancel') }}</b-button>
                                              </div>
                                          </div>
                                      </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>

        <b-modal id="modal-5" size="xl" :title="this.$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <Details :upazilaData="upazilaData" source="2" @child-method="handleChildMethod" :key="upazilaData"></Details>
        </b-modal>
    </b-container>
</template>
<script>
// import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { farmAllocationStore, farmAllocationUpdate, circularListApi, machineTypeListApi, existDistrictAllocationApi } from '../../api/routes'
import Details from './FarmerDetails'

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        Details
    },
    created () {
        if (this.id) {
            const tmp = this.getAllocationData()
            if (tmp) {
                this.formData = tmp
            }
        } else {
          this.upazilaList = this.getUpazilaList()
        }
        this.getCircularList()
    },
    data () {
        return {
            currentUpazilaID: 0, // this is for farmer list
            upazilaData: {},
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            formData: {
                fiscal_year_id: 0,
                season_id: 0,
                circular_id: 0,
                district_id: null,
                details: []
            },
            myFilter: (option, text, search) => {
                const temp = search.toLowerCase()
                return option.text_en.toLowerCase().indexOf(temp) > -1 ||
                option.text_bn.toLowerCase().indexOf(temp) > -1
            },
            status: 1,
            circularList: [],
            circularLoading: false,
            upazilaList: [],
            loadMechineType: false,
            machineTypeList: [],
            existAllocationDistrict: false
        }
    },
    computed: {
      id () {
        return this.$route.query.id
      },
      currentLocale () {
        return this.$i18n.locale
      },
      fiscalYearList: function () {
          return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      seasonList: function () {
        return [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Aus' : 'আউশ', text_en: 'Aus', text_bn: 'আউশ' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Amon' : 'আমন', text_en: 'Amon', text_bn: 'আমন' },
          { value: 3, text: this.$i18n.locale === 'en' ? 'Boro' : 'বোরো', text_en: 'Boro', text_bn: 'বোরো' }
        ]
      },
      districtList: function () {
        return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      }
    },
    watch: {
      'formData.fiscal_year_id': function (newVal, oldVal) {
        if (newVal) {
          this.getCircularList()
          if (!this.id) {
            this.checkExistAllocationDistrictWise()
          }
        }
      },
      'formData.season_id': function (newVal, oldVal) {
        if (newVal) {
          this.getCircularList()
        }
      },
      'formData.district_id': function (newVal, oldVal) {
        if (newVal) {
           this.upazilaList = this.getUpazilaList(newVal)
           this.getMachineTypeList()
           if (!this.id) {
            this.checkExistAllocationDistrictWise()
           }
        }
      },
      currentLocale: function (newVal, oldVal) {
        this.getCircularList()
        if (this.formData.district_id) {
            this.upazilaList = this.getUpazilaList(this.formData.district_id)
            this.getMachineTypeList()
        }
      }
    },
    methods: {
        handleChildMethod (data) {
            this.formData.details.map((item, index) => {
                if (item.upazilla_id === this.currentUpazilaID) {
                    this.formData.details[index].approved_farmers = data
                }
            })
        },
        details (item) {
            this.currentUpazilaID = 0
            this.upazilaData = item
            this.upazilaData.circular_id = this.formData.circular_id
            this.upazilaData.fiscal_year_id = this.formData.fiscal_year_id
            this.upazilaData.season_id = this.formData.season_id
            this.currentUpazilaID = item.upazilla_id
        },
        back () {
            return this.$router.go(-1)
        },
        getAllocationData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            if (tmpData !== undefined) {
            return JSON.parse(JSON.stringify(tmpData))
            }
        },
        async saveData (status = 1) {
            this.loading = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            const loadingState = { loading: false, listReload: false }
            this.formData.status = status
            var check = await this.$refs.form.validate()

                if (check) {
                    if (this.id) {
                        result = await RestApi.putData(incentiveGrantServiceBaseUrl, `${farmAllocationUpdate}/${this.id}`, this.formData)
                    } else {
                        result = await RestApi.postData(incentiveGrantServiceBaseUrl, farmAllocationStore, this.formData)
                    }

                    loadingState.listReload = true
                    this.$store.dispatch('mutateCommonProperties', loadingState)
                    this.loading = false
                    if (result.success) {
                        this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                        this.$toast.success({
                            title: this.$t('globalTrans.success'),
                            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                            color: '#D6E09B'
                        })
                        if (this.$route.query.from) {
                            this.$router.push({ name: this.$route.query.from })
                        }
                        this.formData.district_id = 0
                    } else {
                        this.$toast.error({
                            title: 'Success',
                            message: result.message,
                            color: '#D6E09B'
                        })
                        this.$refs.form.setErrors(result.errors)
                    }
                } else {
                    this.loading = false
                    this.$store.commit('incentiveGrant/mutateIncentiveGrantServiceProperties', { hasDropdownLoaded: false })
                }
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
            if (districtId) {
            return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
        },
        async getCircularList () {
            this.circularLoading = true
            const params = {
            fiscal_year_id: this.formData.fiscal_year_id,
            season_id: this.formData.season_id,
            lang: this.currentLocale
            }
            let result = null
            result = await RestApi.getData(incentiveGrantServiceBaseUrl, circularListApi, params)
            if (result.success) {
                this.circularList = result.data
            } else {
                this.circularList = []
            }
            this.circularLoading = false
        },
        async checkExistAllocationDistrictWise () {
            const params = {
            fiscal_year_id: this.formData.fiscal_year_id,
            season_id: this.formData.season_id,
            district_id: this.formData.district_id,
            circular_id: this.formData.circular_id
            }
            let result = null
            result = await RestApi.getData(incentiveGrantServiceBaseUrl, existDistrictAllocationApi, params)
            if (result.success) {
                this.existAllocationDistrict = result.success
                this.formData = result.data
                if (result.data.status === 1) {
                    this.existAllocationDistrict = false
                }
            } else {
                this.existAllocationDistrict = result.success
            }
        },
        async getMachineTypeList () {
            const params = {
            circular_id: this.formData.circular_id
            }
            this.loadMechineType = true
            let result = null
            result = await RestApi.getData(incentiveGrantServiceBaseUrl, machineTypeListApi, params)
            if (result.success) {
                this.machineTypeList = result.data
                if (!this.id) {
                    this.setUpazillaMechineAllocation(result.data)
                }
            } else {
                this.machineTypeList = []
            }
            this.loadMechineType = false
        },
        setUpazillaMechineAllocation (mechineType) {
            this.formData.details = this.upazilaList.map(item => {
                var machineTypeInfo = []
                mechineType.forEach(element => {
                    element.instruments.forEach(mechine => {
                    const data = {
                        machine_type_id: element.id,
                        instrument_id: mechine.id,
                        quantity: 0
                    }
                    machineTypeInfo.push(data)
                    })
                })

                return Object.assign({}, item, { machine_type_info: machineTypeInfo }, { upazilla_id: item.value })
            })
        },
        getUpazilaName (id) {
            const obj = this.$store.state.commonObj.upazilaList.find(item => item.value === id)
            return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
        }
    }
}
</script>
