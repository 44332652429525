<template>
    <b-container fluid>
       <b-overlay :show="loading">
        <b-row>
          <b-col lg="12" sm="12">
            <iq-card>
              <template>
                  <b-row>
                    <b-col lg="12" sm="12">
                      <b-row>
                        <b-col lg="12" sm="12">
                          <div class="mx-2">
                             <b-table-simple small resonsive borderless v-if="upazilaData">
                                <b-tr v-if="upazilaData.district_id">
                                    <b-th width="15%">{{ $t('globalTrans.fiscal_year') }}</b-th>
                                    <b-th width="2%">:</b-th>
                                    <b-td>
                                        {{ getFiscalYearName(upazilaData.fiscal_year_id) }}
                                    </b-td>
                                    <b-th width="15%">{{ $t('farm_config.season') }}</b-th>
                                    <b-th width="2%">:</b-th>
                                    <b-td>
                                        {{ getSeasonName(upazilaData.season_id) }}
                                    </b-td>

                                </b-tr>
                                 <b-tr>
                                    <b-th width="15%">{{ $t('farm_config.circular') }}</b-th>
                                    <b-th width="2%">:</b-th>
                                    <b-td>
                                        {{ getCircularName(upazilaData.circular_id) }}
                                    </b-td>
                                    <b-th width="15%">{{ $t('globalTrans.district') }}</b-th>
                                    <b-th width="2%">:</b-th>
                                    <b-td>
                                        {{ getDistrictName(upazilaData.district_id) }}
                                    </b-td>
                                </b-tr>
                            </b-table-simple>
                          </div>
                          <!-- farmer list -->
                           <div  class="mx-2">
                            <h5><b>{{$t('farm_allocation.farmer_list')}} :</b></h5>
                              <b-table-simple striped bordered small class="mt-2">
                                <b-thead>
                                    <tr class="text-center">
                                        <b-th class="text-center" v-if="parseInt(source) === 1">{{ $t('globalTrans.upazila') }}</b-th>
                                        <b-th class="text-center">{{ $t('globalTrans.name') }}</b-th>
                                        <b-th class="text-center">{{ $t('globalTrans.father_name') }}</b-th>
                                        <b-th class="text-center">{{ $t('globalTrans.mobile') }}</b-th>
                                        <b-th class="text-center">{{ $t('globalTrans.nid') }}</b-th>
                                        <b-th class="text-center">{{ $t('farm_config.machine_name') }}</b-th>
                                        <b-th class="text-center">{{ $t('farm_config.brand') }}</b-th>
                                        <b-th class="text-center">{{ $t('globalTrans.union') }}</b-th>
                                        <b-th class="text-center">{{ $t('farm_allocation.priority') }}</b-th>
                                        <b-th class="text-center" v-if="parseInt(source) === 2">{{ $t('globalTrans.action') }} </b-th>
                                    </tr>
                                </b-thead>
                                <template v-if="detailsData && detailsData.farmarList.length > 0">
                                    <b-tbody>
                                        <b-tr v-for="(farmer, farmerIndex) in detailsData.farmarList" :key="'farmer-list-' + farmerIndex">
                                            <!-- <b-td  class="text-center">{{ $n(farmerIndex + 1) }}</b-td> -->
                                            <b-td class="text-center">{{ currentLocale === 'en' ? farmer.farmar_name_en : farmer.farmar_name_bn }}</b-td>
                                            <b-td class="text-center">{{ currentLocale === 'en' ? farmer.father_name : farmer.father_name_bn }}</b-td>
                                            <b-td class="text-center"> {{ ($i18n.locale == 'bn' ? '০': '0') + $n(farmer.mobile_no, { useGrouping: false }) }}</b-td>
                                            <b-td class="text-center">{{ $n(farmer.nid_no, { useGrouping: false }) }}</b-td>
                                            <b-td class="text-center">{{ getInstrumentName(farmer.instrument_id)}}</b-td>
                                            <b-td class="text-center">{{ getBrandName(farmer.brand_id)}}</b-td>
                                            <b-td class="text-center">{{ farmer.union_id ? getUnionName(farmer.union_id) : '-'}}</b-td>
                                            <b-td class="text-center">{{ $n(farmer.sorting) }}</b-td>
                                            <b-td class="text-center" style="width:20%" v-if="parseInt(source) === 2">
                                                <b-form-checkbox v-model="approvedSelectedIds" :value="farmer.mac_farmer_id" class="d-inline-block ml-1">
                                                </b-form-checkbox>
                                            </b-td>
                                        </b-tr>
                                        <b-tr v-if="parseInt(source) === 2">
                                            <b-td colspan="10" style="text-align:right">
                                                <b-button type="button" class="btn-sm" variant="success" @click="triggerEvent">{{$t('globalTrans.save')}}</b-button>
                                            </b-td>
                                        </b-tr>
                                    </b-tbody>
                                </template>
                                <template v-if="detailsData2">
                                    <b-tbody>
                                        <template v-for="(upazila, upazilaIndex) in detailsData2">
                                            <b-tr v-for="(farmer, farmerIndex) in upazila" :key="'farmer-list-' + farmerIndex">
                                                <b-td v-if="farmerIndex === 0" :rowspan="farmerIndex === 0 ? upazila.length : ''" class="text-center align-middle" :key="'upazilaIndex-list-' + upazilaIndex">{{ getUpazilaName(upazilaIndex) }}</b-td>
                                                <b-td class="text-center">{{ currentLocale === 'en' ? farmer.farmar_name_en : farmer.farmar_name_bn }}</b-td>
                                                <b-td class="text-center">{{ currentLocale === 'en' ? farmer.father_name : farmer.father_name_bn }}</b-td>
                                                <b-td class="text-center"> {{ ($i18n.locale == 'bn' ? '০': '0') + $n(farmer.mobile_no, { useGrouping: false }) }}</b-td>
                                                <b-td class="text-center">{{ $n(farmer.nid_no, { useGrouping: false }) }}</b-td>
                                                <b-td class="text-center">{{ getInstrumentName(farmer.instrument_id)}}</b-td>
                                                <b-td class="text-center">{{ getBrandName(farmer.brand_id)}}</b-td>
                                                <b-td class="text-center">{{ farmer.union_id ? getUnionName(farmer.union_id) : '-'}}</b-td>
                                                <b-td class="text-center">{{ $n(farmer.sorting) }}</b-td>
                                                <b-td class="text-center" style="width:20%" v-if="parseInt(source) === 2">
                                                <b-form-checkbox v-model="approvedSelectedIds" :value="farmer.mac_farmer_id" class="d-inline-block ml-1">
                                                </b-form-checkbox>
                                                    <!-- <b-button type="button" v-if="assignOptionVisible(farmer.instrument_id)" v-b-modal.modal-5 class="btn-sm mr-2" variant="primary" @click="assignToSupplier(farmer)">{{$t('farm_config.assign')}}</b-button>
                                                    <b-button type="button" class="btn-sm" variant="danger" @click="farmerApplicationWidthdraw(farmer)">{{$t('farm_config.withdraw')}}</b-button> -->
                                                </b-td>
                                            </b-tr>
                                        </template>
                                    </b-tbody>
                                </template>
                                <template v-if="dontHaveData">
                                    <b-tbody>
                                        <b-tr>
                                            <b-td colspan="10" align="center">{{ $t('globalTrans.noDataFound') }}</b-td>
                                        </b-tr>
                                    </b-tbody>
                                </template>
                            </b-table-simple>
                          </div>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
              </template>
            </iq-card>
          </b-col>
        </b-row>
        </b-overlay>
      </b-container>
  </template>
  <script>
    import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
    import { approvedFarmerListApi, selectedFarmerListApi } from '../../api/routes'
    export default {
        name: 'FormLayout',
        props: ['upazilaData', 'source'],
        data () {
            return {
                dontHaveData: false,
                approvedSelectedIds: [],
                detailsData: {
                    circular: {},
                    machineTpeList: [],
                    instrumentList: [],
                    farmarList: []
                },
                detailsData2: {
                    circular: {},
                    machineTpeList: [],
                    instrumentList: [],
                    farmarList: []
                },
                item: {},
                slOffset: 1,
                loadMechineType: false,
                upazilla_id: 0
            }
        },
        created () {
            if (this.upazilaData) {
                this.getData(this.upazilaData.circular_id)
            }
        },
        computed: {
            loading () {
                return this.$store.state.commonObj.loading
            },
            authUser () {
                return this.$store.state.Auth.authUser
            },
            currentLocale () {
                return this.$i18n.locale
            },
            seasonList: function () {
                return [
                { value: 1, text: this.$i18n.locale === 'en' ? 'Aus' : 'আউশ', text_en: 'Aus', text_bn: 'আউশ' },
                { value: 2, text: this.$i18n.locale === 'en' ? 'Amon' : 'আমন', text_en: 'Amon', text_bn: 'আমন' },
                { value: 3, text: this.$i18n.locale === 'en' ? 'Boro' : 'বোরো', text_en: 'Boro', text_bn: 'বোরো' }
                ]
            }
        },
        watch: {
        currentLocale: function (newVal, oldVal) {
        },
        loading: function (newVal, oldVal) {
            if (newVal) {
            this.getData(this.upazilaData.circular_id)
            }
        }
        },
        methods: {
            triggerEvent () {
                this.$emit('child-method', this.approvedSelectedIds)
                this.$bvModal.hide('modal-5')
            },
            getDistrictName (districtId) {
                const obj = this.$store.state.commonObj.districtList.find(el => el.value === parseInt(districtId))
                if (obj !== 'undefinded') {
                return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
                }
            },
            getUpazilaName (upazilaId) {
                const obj = this.$store.state.commonObj.upazilaList.find(el => el.value === parseInt(upazilaId))
                if (obj !== 'undefinded') {
                return this.$i18n.locale === 'en' ? obj.text_en : obj.text_bn
                }
            },
            getUnionName (unionId) {
                const obj = this.$store.state.commonObj.unionList.find(el => el.value === parseInt(unionId))
                if (obj !== 'undefinded') {
                return this.$i18n.locale === 'en' ? obj?.text_en : obj?.text_bn
                }
            },
            getFiscalYearName (id) {
                const obj = this.$store.state.commonObj.fiscalYearList.find(item => item.value === id)
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getInstrumentName (id) {
                const obj = this.$store.state.incentiveGrant.commonObj.instrumentList.find(item => item.value === id)
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getBrandName (id) {
                const obj = this.$store.state.incentiveGrant.commonObj.macBrandList.find(item => item.value === id)
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            getSeasonName (id) {
                const obj = this.seasonList.find(item => item.value === id)
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            },
            async getData () {
                const params = {
                    circular_id: this.upazilaData.circular_id
                }
                params.upazilla_id = this.upazilaData.upazilla_id

                if (this.authUser.role_id !== 1 && this.authUser.is_org_admin === 0) {
                    if (this.authUser.office_detail.upazilla_id) {
                        params.upazilla_id = this.authUser.office_detail.upazilla_id
                        this.upazilla_id = this.authUser.office_detail.upazilla_id
                    }
                }

                let result = null
                this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })

                if (parseInt(this.source) === 1) {
                    result = await RestApi.getData(incentiveGrantServiceBaseUrl, selectedFarmerListApi, params)

                    if (result.success) {
                        this.detailsData2 = result.data
                        if (this.upazilaData.approved_farmers) {
                            this.approvedSelectedIds = this.upazilaData.approved_farmers
                        }
                    } else {
                        this.dontHaveData = true
                        this.detailsData2 = {}
                    }
                }
                if (parseInt(this.source) === 2) {
                    result = await RestApi.postData(incentiveGrantServiceBaseUrl, approvedFarmerListApi, params)
                    if (result.success) {
                        this.detailsData = result.data
                        if (this.upazilaData.approved_farmers) {
                            this.approvedSelectedIds = this.upazilaData.approved_farmers
                        }
                    } else {
                        this.dontHaveData = true
                        this.detailsData = {}
                    }
                }

                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            },
            assignOptionVisible (instrumentId) {
                const obj = this.detailsData.instrumentList.find(item => item.instrument_id === instrumentId)
                if (obj) {
                if (obj.quantity > obj.distributed_qty) {
                    return true
                }
                return false
                } else {
                return false
                }
            },
            getCircularName (id) {
                const obj = this.$store.state.incentiveGrant.commonObj.circularList.find(item => item.value === id)
                return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
            }
        }
    }
  </script>
